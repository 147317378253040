@import '~foundation-sites/scss/util/unit';
@import '~foundation-sites/scss/util/value';

$color-alabaster: #F7F7F7;
$color-alto: #D9D9D9;
$color-alto-light: #D9D9D9;
$color-alizarin-crimson: #d92626;
$color-aqua-forest: #59ae7a;
$color-aqua-haze: #F4F6F9;
$color-athens-gray: #F0F3F6;
$color-azure: #3A63A6;
$color-bermuda-gray: #6C83A7;
$color-biscay: #1E3B68;
$color-black: #000000;
$color-blue-romance: #d1f5d1;
$color-bombay: #ADADAD;
$color-boulder: #767676;
$color-botticelli: #D1DAE7;
$color-casablanca: #F7C244;
$color-casper: #B4C0D2;
$color-catskill-white: #ECF0F5;
$color-cerulean: #05B8F0;
$color-chambray: #355990;
$color-corn: #F7BF06;
$color-danube: #658CC6;
$color-deluge: #6F59AE;
$color-dove-gray: #676767;
$color-dusty-gray: #979797;
$color-emerald: #62CF68;
$color-flush-orange: #FF7F00;
$color-foam: #F3FCFE;
$color-fruit-salad: #4FA46F;
$color-gallery: #EFEFEF;
$color-gray: #8B8B8B;
$color-hawkes-blue: #F2F8FE;
$color-heather: #C1C9D7;
$color-hippie-blue: #59a0ae;
$color-iron: #D8D8DA;
$color-kashmir-blue: #49699B;
$color-mandy: #E65B5B;
$color-mercury: #E6E6E6;
$color-mine-shaft: #3E3E3E;
$color-mystic: #E1E6EF;
$color-nepal: #8CA2C2;
$color-oslo-gray: #999C9D;
$color-picton-blue: #1FB9E7;
$color-pigeon-post: #B8C9DB;
$color-polar: #E8F6FA;
$color-porcelain: #ced0d2;
$color-rock-blue: #9AACC7;
$color-salem: #0EA535;
$color-scampi: #595FAE;
$color-scorpion: #595959;
$color-seashell: #F1F1F1;
$color-shilo: #E6A2A2;
$color-silver: #B8B8B8;
$color-sundown: #FFB2B2;
$color-tradewind: #59aea3;
$color-vivid-tangerine: #FF7F7F;
$color-waikawa-gray: #5D7AA6;
$color-wedgewood: #5982ae;
$color-west-side: #FF8E1D;
$color-white: #FFFFFF;
$color-wild-blue-yonder: #798FB6;

// Default text colors
$default-text-color: $color-mine-shaft !default;
$secondary-text-color: $color-dove-gray !default;
$dimmed-text-color: $color-bombay !default;

// Default link colors
$default-link-color: $color-bermuda-gray !default;
$default-link-hover-color: $color-azure !default;

// General variables
$default-border-radius: rem-calc(2) !default;
$single-border-radius: rem-calc(2) !default;
$double-border-radius: rem-calc(4) !default;
$triple-border-radius: rem-calc(6) !default;
$default-font-face: 'Source Sans Pro' !default;
$default-font-size: 16px !default;
$default-margin: 1.5rem !default;
$default-line-height: 1.4 !default;
$default-box-shadow: 0 rem-calc(2) rem-calc(6) rgba($color-black, 0.1) !default;
$second-box-shadow: 0px 0px rem-calc(8) rgba(0,0,0,0.101) !default;
$third-box-shadow: 0px 0px rem-calc(4) rgba(0,0,0,0.08) !default;
$default-transition: 0.3s ease-in !default;
$default-transform-transition: transform $default-transition !default;

// Forms
$default-border-color: $color-iron !default;
$default-border-focus-color: $color-wild-blue-yonder !default;
$default-border-error-color: $color-sundown !default;
$default-label-color: $color-bermuda-gray !default;
$default-input-placeholder-color: $color-bombay !default;
$default-button-transition: all 0.25s ease-out !default;
$default-error-color: $color-sundown !default;

$select-radius: $default-border-radius !default;
$select-background: $color-alabaster !default;
$select-triangle-color: $color-bermuda-gray !default;
$select-angle-color: $color-picton-blue !default;
$select-disabled-triangle-color: lighten($color-bermuda-gray, 25%) !default;
$select-color: $default-text-color !default;

$input-icon-padding: 2.25rem !default;
$input-classic-icon-padding: 3rem !default;

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Flex table
$flex-table-tablet-vertical-padding: 0.5rem !default;
$flex-table-tablet-horizontal-padding: 0.375rem !default;
$flex-table-padding-horizontal: 1rem !default;
$flex-table-padding-horizontal-big: 2.5rem !default;
$flex-table-padding-vertical: 1rem !default;
$flex-table-border-color: $color-gallery !default;
$flex-table-border-color-light: $color-mercury !default;
$flex-table-font-size: rem-calc(14) !default;
$flex-table-tablet-font-size: 0.75rem !default;
$flex-table-bg-color: $color-white !default;
$flex-table-even-bg-color: $color-alabaster !default;

// Side panel
$side-panel-width: rem-calc(300) !default;
$side-panel-min-width: rem-calc(200) !default;
$side-panel-max-width: rem-calc(900) !default;
$side-panel-background: $color-chambray !default;
$side-panel-border-width: 1px;
$side-panel-border-bottom: $side-panel-border-width solid rgba($color-biscay, 0.3) !default;

// Top bar
$top-bar-height: rem-calc(48) !default;

// Roles
$role-margin: 0.25rem !default;

// Spinner
$spinner-size: 1rem !default;
$spinner-size-big: 3rem !default;
$spinner-border-size: rem-calc(4) !default;
