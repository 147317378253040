@keyframes openDropdown {
  from {
    opacity: 0;
    visibility: hidden;
    z-index: auto;
  }
  to {
    opacity: 1;
    visibility: visible;
    z-index: index($dropdown, list);
  }
}

@keyframes closeDropdown {
  from {
    opacity: 1;
    visibility: visible;
    z-index: index($dropdown, list);
  }
  to {
    opacity: 0;
    visibility: hidden;
    z-index: auto;
  }
}
