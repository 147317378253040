@supports (-webkit-overflow-scrolling: touch) {
  html, body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

body {
  font-family: $default-font-face;
  background-color: $color-white;
  line-height: $default-line-height;
  color: $default-text-color;

  &.body--frozen {
    overflow: hidden;
  }

  &.body--fixed {
    position: fixed;
    left: 0;
    right: 0;
  }

  &.body--disabled-user-select {
    user-select: none;
  }

  &.body--alabaster {
    background-color: $color-alabaster;
  }

  &.body--chambray {
    background-color: $color-chambray;
  }
}

.content {
  padding: {
    top: 2.5rem;
    bottom: 2.5rem;
  };
  transition: 0.5s opacity ease-in;
}

.content--disabled {
  opacity: 0.3;
}

.columns {
  &.columns--without-padding {
    padding: {
      left: 0;
      right: 0;
    };
  }

  &.columns--without-left-padding {
    padding-left: 0;
  }

  &.columns--without-right-padding {
    padding-right: 0;
  }
}

.row, .columns {
  &:before,
  &:after {
    content: normal;
  }
}

.no-margin {
  margin: 0;
}

.hidden {
  display: none;
}
