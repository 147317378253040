h1,
h2,
h3,
h5 {
  color: $color-chambray;
}

h1 {
  font: {
    size: 1.5rem;
    weight: $font-weight-regular;
  }
  margin-bottom: 1rem;

  &.h1--blue {
    color: $color-chambray;
    font-weight: $font-weight-semibold;
  }
}

h2 {
  font: {
    size: rem-calc(20);
    weight: $font-weight-semibold;
  }
  margin-bottom: 1rem;

  &.h2--light {
    font-weight: $font-weight-regular;
  }

  &.h2--blue {
    color: $color-wild-blue-yonder;
  }

  &.h2--dark {
    color: $default-text-color;
  }

  &.h2--grey {
    color: $color-scorpion;
  }

  &.h2--small {
    font-size: rem-calc(18);
  }

  &.h2--big {
    font-size: rem-calc(24);
  }

  &.h2--no-margin {
    margin-bottom: 0;
  }

  small {
    font-weight: $font-weight-regular;
    font-size: inherit;
    color: inherit;
  }
}

h3 {
  font: {
    size: 1rem;
    weight: $font-weight-regular;
  }
  margin-top: 0.25rem;
  margin-bottom: $default-margin;

  small {
    color: $color-nepal;
    font: {
      size: 1rem;
      weight: $font-weight-regular;
    }
  }
}

h4 {
  display: inline-block;
  margin-bottom: 0.5rem;
  font: {
    size: inherit;
    weight: $font-weight-semibold;
  }

  &.h4--light {
    font-weight: $font-weight-regular;
  }

  small {
    font-weight: $font-weight-regular;
    color: inherit;

    &:before {
      content: '\00a0';
    }
  }
}

h5 {
  font: {
    size: 1rem;
    weight: $font-weight-semibold;
  }
  text-transform: uppercase;
  margin-bottom: 1rem;
}

%h__icon {
  font-size: rem-calc(13);
}

.h__icon--before {
  @extend %h__icon;
  margin-right: 0.5rem;
}

.h__icon--after {
  @extend %h__icon;
  margin-left: 0.5rem;
}
