@import './../base/variables';

$default-modal-padding: 2rem;

.modal__heading {
  display: flex;
  justify-content: space-between;
}

.modal-heading__list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modal-heading__item:not(:last-child) {
  margin-right: 1rem;
}

.modal-heading__button {
  padding: 0;
}

.modal--off-canvas {
  &.modal--no-padding-content {
    .modal__heading {
      padding: {
        right: $default-modal-padding;
        left: $default-modal-padding;
      }
    }
  }
}

