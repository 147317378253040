// Foundation variables
$button-radius: $default-border-radius;
$button-transition: $default-button-transition;

// App variables
$primary-btn-color: $color-west-side;
$primary-btn-hover-color: $color-flush-orange;
$primary-btn-text-color: $color-white;

$secondary-btn-color: $color-west-side;
$secondary-btn-hover-color: $color-flush-orange;
$secondary-btn-text-color: $color-west-side;
$secondary-bg-color: $color-white;

$tertiary-btn-color: $default-link-color;
$tertiary-btn-hover-color: $default-link-hover-color;
$tertiary-btn-text-color: $default-link-color;

$quaternary-btn-color: $color-danube;
$quaternary-btn-hover-color: $color-azure;
$quaternary-btn-text-color: $color-white;

$fifthenary-btn-color: $color-alabaster;
$fifthenary-btn-hover-color: $color-seashell;
$fifthenary-btn-text-color: $color-bombay;
$fifthenary-btn-border-color: $color-mercury;

$sixthary-btn-color: $color-botticelli;
$sixthary-btn-text-color: $color-chambray;
$sixthary-btn-border-color: rgba($color-rock-blue, 0.5);
$sixthary-btn-light-color: $color-white;
$sixthary-btn-light-text-color: $color-boulder;
$sixthary-btn-light-border-color: rgba($color-alto, 0.7);
$sixthary-btn-hover-color: $color-pigeon-post;
$sixthary-btn-hover-text-color: $color-chambray;
$sixthary-btn-hover-border-color: rgba($color-rock-blue, 0.5);
$sixthary-btn-active-color: $color-chambray;
$sixthary-btn-active-text-color: $color-white;
$sixthary-btn-active-border-color: $color-chambray;
$sixthary-btn-active-hover-color: darken($color-chambray, 10%);

$seventhary-btn-color: $color-botticelli;
$seventhary-btn-hover-color: $color-casper;
$seventhary-btn-text-color: $color-chambray;

$button-sizes: (
  tiny: (
    font-size: 0.875rem,
    padding: 0.5rem,
    icon-margin: 0.25rem,
    icon-size: rem-calc(12),
    icon-vertical-align: 0
  ),
  small: (
    font-size: 0.875rem,
    padding: 0.5rem 1rem,
    icon-margin: 0.5rem,
    icon-size: rem-calc(16),
    icon-vertical-align: rem-calc(-2),
  ),
  thin: (
    font-size: 1rem,
    padding: rem-calc(6) 1rem,
    icon-margin: 0.5rem,
    icon-size: 1rem,
    icon-vertical-align: rem-calc(-1),
  ),
  big: (
    font-size: 1.125rem,
    padding: 0.625rem 3.125rem 0.688rem,
  )
);

%button {
  display: inline-block;
  font-family: $body-font-family;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  vertical-align: baseline;
  line-height: 1;
  text-align: center;
  margin-bottom: 0;
  padding: {
    top: 0.75rem;
    right: 1rem;
    bottom: 0.875rem;
    left: 1rem;
  };
  cursor: pointer;
  transition: $button-transition;

  &:focus {
    outline: auto;
    outline-color: $color-chambray;
  }

  &:disabled,
  &.button--disabled {
    opacity: 0.5;
    cursor: default;
  }
}

@each $size, $map in map-remove($button-sizes, default) {
  .button--#{$size} {
    font-size: map-get($map, font-size);
    padding: map-get($map, padding);

    %button__icon--#{$size} {
      font-size: map-get($map, icon-size);
      vertical-align: map-get($map, icon-vertical-align);
    }

    .button__icon {
      @extend %button__icon--#{$size};
    }

    .button__icon--after {
      @extend %button__icon--#{$size};
      margin-left: map-get($map, icon-margin);
    }

    .button__icon--before {
      @extend %button__icon--#{$size};
      margin-right: map-get($map, icon-margin);
    }
  }
}

.button--primary {
  @extend %button;
  @include button-style($primary-btn-color, $primary-btn-hover-color, $primary-btn-text-color, 0);
}

.button--secondary {
  @extend %button;
  color: $secondary-btn-color;
  border-color: $secondary-btn-color;
  border: rem-calc(1) solid $secondary-btn-color;
  background-color: $secondary-bg-color;

  &:hover, &:focus {
    color: $secondary-btn-hover-color;
    border-color: $secondary-btn-hover-color;
    background-color: $secondary-bg-color;
  }

  .spinner {
    border: {
      color: rgba($secondary-btn-color, 0.2);
      left-color: $secondary-btn-color;
    };
  }
}

.button--tertiary {
  @extend %button;
  @include button-hollow;
  @include button-hollow-style($tertiary-btn-color, 0%);

  &:hover, &:focus {
    color: $tertiary-btn-hover-color;
    border-color: $tertiary-btn-hover-color;
  }

  .spinner {
    border: {
      color: rgba($tertiary-btn-color, 0.2);
      left-color: $tertiary-btn-color;
    };
  }

  &.button--tertiary--rounded {
    border-radius: $double-border-radius;
  }

  &.button--tertiary--white-background {
    background: $color-white;
  }
}

.button--quaternary {
  @extend %button;
  @include button-hollow;
  @include button-hollow-style($color-casper, 0%);
  color: $color-casper;

  &:hover, &:focus {
    border-color: $quaternary-btn-color;
    color: $quaternary-btn-color;
  }

  &.button--active {
    @include button-style($quaternary-btn-color, $quaternary-btn-hover-color, $quaternary-btn-text-color, 0);
    border-color: $quaternary-btn-color;

    &:hover, &:focus {
      border-color: $quaternary-btn-hover-color;
    }
  }
}

.button--fifthenary {
  @extend %button;
  @include button-style($fifthenary-btn-color, $fifthenary-btn-hover-color, $fifthenary-btn-text-color, 0);
  border: rem-calc(1) solid $fifthenary-btn-border-color;
}

.button--sixternary {
  @extend %button;
  @include button-style($fifthenary-btn-color, $fifthenary-btn-hover-color, $fifthenary-btn-text-color, 0);

  &.button--active {
    @include button-style($quaternary-btn-color, $quaternary-btn-hover-color, $quaternary-btn-text-color, 0);
    border-color: $quaternary-btn-color;

    &:hover, &:focus {
      border-color: $quaternary-btn-hover-color;
    }
  }
}

.button--sixthary {
  @extend %button;
  color: $sixthary-btn-text-color;
  background: $sixthary-btn-color;
  border: 1px solid $sixthary-btn-border-color;
  border-radius: rem-calc(4);

  &:hover, &:focus {
    color: $sixthary-btn-hover-text-color;
    background: $sixthary-btn-hover-color;
    border-color: $sixthary-btn-hover-border-color;
  }

  &.button--light {
    color: $sixthary-btn-light-text-color;
    background: $sixthary-btn-light-color;
    border-color: $sixthary-btn-light-border-color;

    &:hover, &:focus {
      color: $sixthary-btn-hover-text-color;
      background: $sixthary-btn-hover-color;
      border-color: $sixthary-btn-hover-border-color;
    }
  }

  &.button--active {
    color: $sixthary-btn-active-text-color;
    background: $sixthary-btn-active-color;
    border-color: $sixthary-btn-active-border-color;

    &:hover, &:focus {
      color: $sixthary-btn-active-text-color;
      background: $sixthary-btn-active-hover-color;
      border-color: $sixthary-btn-active-border-color;
    }
  }

  &:disabled,
  &.button--disabled {
    opacity: 0.4;
  }
}

.button--seventhary {
  @extend %button;
  border-radius: rem-calc(4);
  color: $seventhary-btn-text-color;
  background: $seventhary-btn-color;

  &:hover, &:focus {
    background: $seventhary-btn-hover-color;
  }
}

.button--start-case {
  text-transform: none;
}

.button--link {
  @extend %button;
  @include button-style(transparent, transparent, $default-link-color, 0);

  &:hover, &:focus {
    color: $default-link-hover-color;
  }
}

%button__icon {
  line-height: 0.8rem;
  vertical-align: -0.25rem;
  font-size: 1.5rem;
}

.button__icon {
  @extend %button__icon;
}

.button__icon--before {
  @extend %button__icon;
  margin-right: 0.875rem;
}

.button__icon--after {
  @extend %button__icon;
  margin-left: 1rem;
}

.button__icon--spinner {
  display: inline-block;
  position: relative;
  width: 1rem;
  vertical-align: initial;
}

.button-group {
  button + button,
  .button + .button,
  [class^="button--"] + [class^="button--"]
   {
    margin-left: 1rem;
  }

  .link + button,
  .link + .button,
  .link + [class^="button--"] {
    margin-left: 1.5rem;
  }
}

.button-switch {
  button,
  .button,
  [class^="button--"] {
    &:not(:first-child) {
      border: {
        top-left-radius: 0;
        bottom-left-radius: 0;
        left-width: 0;
      }
    }

    &:not(:last-child) {
      border: {
        top-right-radius: 0;
        bottom-right-radius: 0;
      };
    }
  }

  .button--primary {
    &:not(:last-child) {
      border-right: 1px solid $color-white;
    }
  }
}

.button--icon {
  display: inline-block;
  color: $color-bombay;
  transition: $default-button-transition;
  padding: 0.25rem;

  &.button--icon-blue {
    color: $color-nepal;
  }

  &:hover, &:focus {
    color: $color-gray;
  }
}

.button--nowrap {
  white-space: nowrap;
}

.button--text {
  display: inline-block;
  color: $color-picton-blue;
  cursor: pointer;
  font-size: 1rem;
  font-weight: $font-weight-semibold;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }
}

.button--text-secondary {
  display: inline-block;
  color: $color-dusty-gray;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: underline;
}

.button--text-tertiary {
  @extend .button--text-secondary;
  color: $color-bermuda-gray;
  font-weight: $font-weight-semibold;
  transition: $default-button-transition;

  &:hover, &:focus {
    color: $color-gray;
  }
}

.button--rounded {
  border-radius: $double-border-radius;
}

.button--wide {
  width: 100%;
}

.button--smaller-font {
  font-size: rem-calc(15);
}

.button--big-font {
  font-size: 1rem;
}
