.offline-icon {
  display: table;
  min-width: rem-calc(22);
  height: rem-calc(22);
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background-color: $color-neon-carot;

  @include breakpoint(large up) {
    font-size: 1.2rem;
    min-width: rem-calc(30);
    height: rem-calc(30);
  }

  &.offline-icon--small {
    @include breakpoint(large up) {
      font-size: 1rem;
      min-width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.offline-icon--tiny {
    font-size: 0.75rem;
    min-width: 1rem;
    height: 1rem;

    @include breakpoint(large up) {
      font-size: 0.75rem;
      min-width: 1rem;
      height: 1rem;
    }
  }

  .offline-icon__icon {
    display: table-cell;
    vertical-align: middle;
    color: $color-white;
  }
}

.offline-blanket {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-white, 0.75);

  .offline-blanket__content {
    display: flex;
    min-width: 50%;
    min-height: 50%;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
    color: $color-gray;
    font: {
      size: 1.5rem;
      weight: $font-weight-regular;
    };
    background-color: $color-white;
    box-shadow: $default-box-shadow;
  }
}
