.form__fieldset {
  margin-bottom: 1rem;
}

.form__field {
  margin-bottom: 1.5rem;
}

.form__field--lower {
  margin-bottom: 1rem;
}

.form-field__error {
  display: none;
  background-color: $default-error-color;
  color: $color-white;
  font-size: rem-calc(12);
  border-radius: $default-border-radius;
  margin-left: 0.5rem;
  padding: rem-calc(2) 0.5rem;
}

.form__field--error {

  .label {
    color: $default-error-color;
  }

  .input {
    @extend .input--error;
  }

  .input-classic {
    @extend .input--error;
  }

  .select {
    @extend .select--error
  }

  .textarea {
    @extend .textarea--error;
  }

  .form-field__error {
    display: inline-block;
  }
}

.form__check-box {
  margin-bottom: rem-calc(4);
}

.form__check-box-text {
  font-size: 1rem;
}
