.blankslate {
  border: rem-calc(1) dashed $default-border-color;
  text-align: center;
  padding: 3.7rem 0;
  color: $color-gray;
  font-style: italic;

  &.blankslate--small {
    padding: 1rem 0;
  }
}
