@font-face{
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: url('/assets/fonts/source_sans_pro/EOT/SourceSansPro-Light.eot') format('embedded-opentype'),
    url('/assets/fonts/source_sans_pro/WOFF/OTF/SourceSansPro-Light.otf.woff') format('woff'),
    url('/assets/fonts/source_sans_pro/OTF/SourceSansPro-Light.otf') format('opentype'),
    url('/assets/fonts/source_sans_pro/TTF/SourceSansPro-Light.ttf') format('truetype');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  src: url('/assets/fonts/source_sans_pro/EOT/SourceSansPro-LightIt.eot') format('embedded-opentype'),
    url('/assets/fonts/source_sans_pro/WOFF/OTF/SourceSansPro-LightIt.otf.woff') format('woff'),
    url('/assets/fonts/source_sans_pro/OTF/SourceSansPro-LightIt.otf') format('opentype'),
    url('/assets/fonts/source_sans_pro/TTF/SourceSansPro-LightIt.ttf') format('truetype');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('/assets/fonts/source_sans_pro/EOT/SourceSansPro-Regular.eot') format('embedded-opentype'),
    url('/assets/fonts/source_sans_pro/WOFF/OTF/SourceSansPro-Regular.otf.woff') format('woff'),
    url('/assets/fonts/source_sans_pro/OTF/SourceSansPro-Regular.otf') format('opentype'),
    url('/assets/fonts/source_sans_pro/TTF/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
    src: url('/assets/fonts/source_sans_pro/EOT/SourceSansPro-It.eot') format('embedded-opentype'),
    url('/assets/fonts/source_sans_pro/WOFF/OTF/SourceSansPro-It.otf.woff') format('woff'),
    url('/assets/fonts/source_sans_pro/OTF/SourceSansPro-It.otf') format('opentype'),
    url('/assets/fonts/source_sans_pro/TTF/SourceSansPro-It.ttf') format('truetype');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
    src: url('/assets/fonts/source_sans_pro/EOT/SourceSansPro-Semibold.eot') format('embedded-opentype'),
    url('/assets/fonts/source_sans_pro/WOFF/OTF/SourceSansPro-Semibold.otf.woff') format('woff'),
    url('/assets/fonts/source_sans_pro/OTF/SourceSansPro-Semibold.otf') format('opentype'),
    url('/assets/fonts/source_sans_pro/TTF/SourceSansPro-Semibold.ttf') format('truetype');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
    src: url('/assets/fonts/source_sans_pro/EOT/SourceSansPro-SemiboldIt.eot') format('embedded-opentype'),
    url('/assets/fonts/source_sans_pro/WOFF/OTF/SourceSansPro-SemiboldIt.otf.woff') format('woff'),
    url('/assets/fonts/source_sans_pro/OTF/SourceSansPro-SemiboldIt.otf') format('opentype'),
    url('/assets/fonts/source_sans_pro/TTF/SourceSansPro-SemiboldIt.ttf') format('truetype');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
    src: url('/assets/fonts/source_sans_pro/EOT/SourceSansPro-Bold.eot') format('embedded-opentype'),
    url('/assets/fonts/source_sans_pro/WOFF/OTF/SourceSansPro-Bold.otf.woff') format('woff'),
    url('/assets/fonts/source_sans_pro/OTF/SourceSansPro-Bold.otf') format('opentype'),
    url('/assets/fonts/source_sans_pro/TTF/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
    src: url('/assets/fonts/source_sans_pro/EOT/SourceSansPro-BoldIt.eot') format('embedded-opentype'),
    url('/assets/fonts/source_sans_pro/WOFF/OTF/SourceSansPro-BoldIt.otf.woff') format('woff'),
    url('/assets/fonts/source_sans_pro/OTF/SourceSansPro-BoldIt.otf') format('opentype'),
    url('/assets/fonts/source_sans_pro/TTF/SourceSansPro-BoldIt.ttf') format('truetype');
}
