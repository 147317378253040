$primary-link-color: $color-west-side;
$primary-link-hover-color: $color-flush-orange;
$secondary-link-color: $color-nepal;
$tertiary-link-color: $color-bermuda-gray;

%link {
  display: inline;
  color: $default-link-color;
  font-family: inherit;
  text-transform: uppercase;
  font-weight: $font-weight-semibold;
  cursor: pointer;
  transition: $default-button-transition;
  border: none;
  outline: none;
  background: none;

  &:hover,
  &.active,
  &:focus {
    color: $default-link-hover-color;
  }
}

.link {
  @extend %link;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:disabled,
  &.link--disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.link--static {
    cursor: default;

    &:hover,
    &.active,
    &:focus {
      color: $default-link-color;
    }
  }

  &.link--button {
    text-decoration: underline;
    text-transform: none;

    &:hover,
    &:focus {
      color: $default-link-hover-color;
    }
  }

  .spinner {
    border: {
      color: rgba($default-link-color, 0.2);
      left-color: $default-link-color;
    }
  }
}

.link--with-outline {
  @extend %link;

  &:focus {
    outline: auto;
    outline-color: $color-chambray;
  }
}

.link--with-side-padding {
  padding: 0 1rem;
}

.link--primary {
  @extend .link;
  color: $primary-link-color;

  &:hover,
  &.active,
  &:focus {
    color: $primary-link-hover-color;
  }

  &.link--static {
    &:hover,
    &.active,
    &:focus {
      color: $primary-link-color;
    }
  }
}

.link--secondary {
  @extend .link;
  color: $secondary-link-color;
  text-transform: none;
  font-weight: $font-weight-regular;

  &.link--static {
    &:hover,
    &.active,
    &:focus {
      color: $secondary-link-color;
    }
  }
}

.link--dimmed {
  color: $secondary-text-color;
  text-transform: none;
  font-weight: $font-weight-regular;
}

.link--underline {
  text-decoration: underline;
}

.link--tiny {
  font-size: rem-calc(14);
}

.link--inactive {
  color: $default-link-color;
}

.link--lowercase {
  text-transform: lowercase;
}

.link--classic {
  text-transform: none;
  font-weight: $font-weight-regular;
}

.link--light-blue {
  color: $color-bermuda-gray;
}

.link--dark-blue {
  color: $color-chambray;
}

.link--dove-gray {
  color: $color-dove-gray;

  &:hover,
  &.active,
  &:focus {
    color: $color-dove-gray;
  }
}

.link--semibold {
  font-weight: $font-weight-semibold;
}

.link--no-text-transform {
  text-transform: none;
}

.link--tertiary {
  @extend .link;
  color: $tertiary-link-color;
  font-weight: $font-weight-semibold;
  text-transform: none;

  &.link--tertiary--grey {
    color: $color-scorpion;
  }

  &.link--tertiary--inactive {
    color: $color-boulder;
  }

  &.link--tertiary--outline {
    padding: 0.2rem;

    &:focus {
      outline: auto;
      outline-color: $color-chambray;
    }
  }
}

.link--hover-underline {
  @extend .link;
  color: $tertiary-link-color;
  font-size: rem-calc(15);
  font-weight: $font-weight-regular;
  text-transform: none;

  &:hover {
    text-decoration: underline;
  }
}

.link--list-item-name {
  @extend .link;
  color: $color-scorpion;
  font-weight: $font-weight-semibold;
  text-transform: none;

  &.link--list-item-name--inactive {
    color: $color-bombay;
  }

  &:hover {
    color: $color-chambray;
  }
}

.link--big-font {
  font-size: 1rem;
}
