$gutwin-font-path: "/assets/fonts/icon_font";

$gw-arrow_back: "\e5c4";
$gw-arrow_forward: "\e5c8";

@font-face {
  font-family: 'gutwin';
  src:
    url('#{$gutwin-font-path}/gutwin.woff2') format('woff2'),
    url('#{$gutwin-font-path}/gutwin.ttf') format('truetype'),
    url('#{$gutwin-font-path}/gutwin.woff') format('woff'),
    url('#{$gutwin-font-path}/gutwin.svg#gutwin') format('svg');
  font-weight: normal;
  font-style: normal;
}

.gw-icon,
[class^="gw-"],
[class*=" gw-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'gutwin' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gw-arrow--left:before {
  content: "\e90c";
}

.gw-arrow--right:before {
  content: "\e90d";
}

.gw-pen:before {
  content: "\e900";
}

.gw-check:before {
  content: "\e901";
}

.gw-angle--up:before {
  content: "\e903";
}

.gw-angle--right:before {
  content: "\e91f";
}

.gw-angle--down:before {
  content: "\e902";
}

.gw-angle--left:before {
  content: "\e920";
}

.gw-filter:before {
  content: "\e904";
}

.gw-caret--down:before {
  content: "\e905";
}

.gw-caret--left:before {
  content: "\e906";
}

.gw-caret--right:before {
  content: "\e907";
}

.gw-caret--up:before {
  content: "\e908";
}

.gw-more:before {
  content: "\e909";
}

.gw-list:before {
  content: "\e90a";
}

.gw-calendar:before {
  content: "\e90b";
}

.gw-hamburger:before {
  content: "\e90e";
}

.gw-search:before {
  content: "\e90f";
}

.gw-clock:before {
  content: "\e910";
}

.gw-close:before {
  content: "\e911";
}

.gw-attach:before {
  content: "\e912";
}

.gw-info:before {
  content: "\e913";
}

.gw-questionmark:before {
  content: "\e914";
}

.gw-warning:before {
  content: "\e915";
}

.gw-employee:before {
  content: "\e916";
}

.gw-findings:before {
  content: "\e91a";
}

.gw-audits-list:before {
  content: "\e917";
}

.gw-dashboard:before {
  content: "\e918";
}

.gw-reference-documents:before {
  content: "\e919";
}

.gw-source:before {
  content: "\e91b";
}

.gw-trash:before {
  content: "\e91c";
}

.gw-plus:before {
  content: "\e91d";
}

.gw-plus--bold:before {
  content: "\e91e";
}

.gw-facility:before {
  content: "\e921";
}

.gw-cloud--off:before {
  content: "\e922";
}

.gw-cloud--sync:before {
  content: "\e923";
}

.gw-comment:before {
  content: "\e924";
}

.gw-related:before {
  content: "\e925";
}

.gw-arrow--undo:before {
  content: "\e926";
}

.gw-play:before {
  content: "\e927";
}

.gw-pause:before {
  content: "\e928";
}

.gw-analysis:before {
  content: "\e929";
}

.gw-auditors-count:before {
  content: "\e92e";
}

.gw-completed-audits:before {
  content: "\e92b";
}

.gw-findings-count:before {
  content: "\e92c";
}

.gw-results:before {
  content: "\e92d";
}

.gw-analytics:before {
  content: "\e92f";
}
