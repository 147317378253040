.font-weight--light {
  font-weight: $font-weight-light;
}

.font-weight--regular {
  font-weight: $font-weight-regular;
}

.font-weight--semibold {
  font-weight: $font-weight-semibold;
}

.font-weight--bold {
  font-weight: $font-weight-bold;
}
