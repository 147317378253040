simple-notifications {
  .simple-notification-wrapper {
    width: rem-calc(350);
  }

  .simple-notification {
      border-radius: $double-border-radius;
      box-shadow: $default-box-shadow;
      padding: {
        top: rem-calc(8);
        bottom: rem-calc(12);
      }

      .sn-title,
      .sn-content {
        line-height: inherit;
      }

      .sn-title {
        font-weight: $font-weight-semibold;
      }
    }

}
