$input-background: transparent;
$input-color: $default-text-color;
$input-color-disabled: $color-bombay;
$input-shadow: none;
$input-shadow-focus: none;
$input-vertical-padding: rem-calc(6);
$input-horizontal-padding: rem-calc(9);
$input-classic-vertical-padding: 0.75rem;
$input-classic-horizontal-padding: 1rem;
$input-classic-small-vertical-padding: rem-calc(7);
$input-classic-small-horizontal-padding: rem-calc(10);
$input-classic-small-padding: $input-classic-small-vertical-padding $input-classic-small-horizontal-padding;
$input-with-icon-left-padding: 1.8rem;

%input--focus {
  outline: none;
  border-bottom: rem-calc(2) solid $default-border-focus-color;
  background-color: $input-background;
  padding-bottom: $input-vertical-padding - rem-calc(1);
}

%input--disabled {
  background-color: rgba($color-alto, 0.2);
  cursor: text;
}

%input-classic--focus {
  outline: none;
  border-bottom: rem-calc(2) solid $default-border-focus-color;
  padding-bottom: $input-classic-vertical-padding - rem-calc(1);
}

.input {
  display: block;
  box-sizing: border-box;
  padding: $input-vertical-padding $input-horizontal-padding;
  background-color: $input-background;
  color: $input-color;
  width: 100%;
  height: 2.25rem;
  border: rem-calc(1) solid $input-background;
  border-bottom-color: $default-border-color;
  font: {
    family: inherit;
    size: $input-font-size;
    weight: $input-font-weight;
  }
  white-space: nowrap;
  overflow-x: auto;

  &:focus {
    @extend %input--focus;
  }

  &.input--placeholder,
  &::placeholder {
    color: $default-input-placeholder-color;
  }

  &.input--disabled,
  &:disabled {
    @extend %input--disabled;
  }

  &.input--error {
    border-bottom: {
      width: rem-calc(2);
      color: $default-border-error-color;
    }
  }

  &.input--small {
    font-size: rem-calc(14);
  }
}

.input-asterisk {
  position: relative;

  &:after {
    color: $color-bermuda-gray;
    content: '*';
    font-size: rem-calc(20);
    position: absolute;
    right: rem-calc(8);
    top: rem-calc(12);
  }
}

.input-classic {
  display: block;
  width: 100%;
  color: $input-color;
  padding: $input-classic-vertical-padding $input-classic-horizontal-padding;
  border: rem-calc(1) solid $default-border-color;
  border-radius: $default-border-radius;
  font: {
    family: inherit;
    size: $input-font-size;
  }
  line-height: 1.15;

  &:focus {
    @extend %input-classic--focus;
  }

  &.input--error {
    border-bottom: {
      width: rem-calc(2);
      color: $default-border-error-color;
    }
  }

  &.input-classic--raw {
    border-radius: 0;
  }

  &.input-classic--without-left-border {
    border-left: 0;
  }

  &.input-classic--small {
    padding: $input-classic-small-padding;
    font-size: rem-calc(14);

    &:focus {
      padding-bottom: $input-classic-small-vertical-padding - rem-calc(1);
    }
  }

  &.input-classic--rounded {
    border-radius: $select-radius;
  }

  &.input-classic--alto {
    border-color: $color-alto;

    &:focus {
      outline: none;
      border-bottom: rem-calc(2) solid $color-chambray;
      padding-bottom: $input-classic-vertical-padding - rem-calc(1);
    }
  }

  &.input-classic--with-icon {
    padding-left: $input-with-icon-left-padding;

    &:focus {
      padding-left: $input-with-icon-left-padding;
    }
  }

  &.input--placeholder,
  &::placeholder {
    color: $default-input-placeholder-color;
  }
}

.input-classic--grey {
  @extend .input-classic;
  color: $select-color;
  box-shadow: 0 0 rem-calc(2) 0 rgba($color-black, 0.06), 0 rem-calc(2) rem-calc(2) 0 rgba($color-black, 0.06);
  background-color: $select-background;
  border: rem-calc(1) solid;
  border-color: $color-alabaster;
  border-top-color: $color-white;
  border-radius: $select-radius;
  padding: {
    right: rem-calc(9);
    left: rem-calc(9);
  }

  &:focus {
    background-color: $color-seashell;
    border-color: $color-seashell;
    box-shadow: 0 0 rem-calc(2) 0 rgba($color-black, 0.12), 0 rem-calc(2) rem-calc(2) 0 rgba($color-black, 0.12);
  }
}

.input-classic--error {
  border-bottom: {
    width: rem-calc(2);
    color: $default-border-error-color;
  }
}

.input-icon-group--after,
.input-icon-group--before,
.input-icon-group--both {
  position: relative;

  .input-icon-group__icon {
    position: absolute;
    color: $color-mercury;
    display: inline-block;
    top: rem-calc(7);
    font-size: 1.25rem;

    &.input-icon-group__icon--classic {
      top: rem-calc(12);
    }

    &.input-icon-group__icon--classic-small {
      top: rem-calc(6);
    }

    &.input-icon-group__icon--blue {
      color: $color-bermuda-gray;
    }
  }
}

.input-icon-group--both {
  .input {
    padding-left: $input-icon-padding;
    padding-right: $input-icon-padding;
  }

  .input-classic {
    padding-left: $input-classic-icon-padding;
    padding-right: $input-classic-icon-padding;
  }

  .input-icon-group__icon {
    &:first-of-type {
      left: rem-calc(11);
    }

    &:nth-of-type(2) {
      right: rem-calc(11);
    }
  }
}

.input-icon-group--before {
  .input {
    padding-left: $input-icon-padding;
  }

  .input-classic {
    padding-left: $input-classic-icon-padding;
  }

  .input-icon-group__icon {
    left: rem-calc(11);
  }
}

.input-icon-group--after {
  .input {
    padding-right: $input-icon-padding;
  }

  .input-classic {
    padding-right: $input-classic-icon-padding;
  }

  .input-icon-group__icon {
    right: rem-calc(11);
  }
}
