$textarea-bottom-padding: rem-calc(12);
$textarea-classic-top-padding: rem-calc(11);
$textarea-classic-bottom-padding: rem-calc(13);

.textarea-container {
  position: relative;
  padding: 0;
}

.textarea {
  display: block;
  box-sizing: border-box;
  padding: $input-vertical-padding $input-horizontal-padding $textarea-bottom-padding;
  width: 100%;
  min-height: 4.5rem;
  color: $input-color;
  background-color: $input-background;
  resize: vertical;
  border: rem-calc(1) solid $input-background;
  border-bottom-color: $default-border-color;
  font: {
    family: inherit;
    size: $input-font-size;
    weight: $input-font-weight;
  };
  line-height: 1rem;

  &:focus {
    position: relative;
    outline: none;
    border-bottom-color: $default-border-focus-color;
    background-color: $input-background;

    & + .textarea-border {
      display: block;
    }
  }

  &::placeholder {
    color: $default-input-placeholder-color;
  }

  &.textarea--error {
    border-bottom-color: $default-border-error-color;

    & + .textarea-border {
      display: block;
      border-bottom-color: $default-border-error-color;
    }
  }
}

.textarea--small {
  @extend .textarea;
  height: 2.25rem;
  min-height: 2.25rem;
  font-size: rem-calc(14);
}

.textarea--autosize {
  resize: none;
}

.textarea-classic {
  display: block;
  border: rem-calc(1) solid $default-border-color;
  border-radius: $default-border-radius;
  padding: $textarea-classic-top-padding $input-classic-horizontal-padding $textarea-classic-bottom-padding $input-classic-horizontal-padding;
  width: 100%;
  color: $input-color;
  min-height: rem-calc(44);
  line-height: 1.25rem;

  &::placeholder {
    color: $default-input-placeholder-color;
  }

  &:focus {
    outline: none;

    & + .textarea-border {
      display: block;
    }
  }

  &.textarea-classic--medium {
    min-height: rem-calc(80);
  }

  &.textarea-classic--big {
    min-height: rem-calc(142);
  }

  &.textarea-classic--error {
    border-bottom-color: $default-border-error-color;

    & + .textarea-border {
      display: block;
      border-bottom-color: $default-border-error-color;
    }
  }

  & + .textarea-border {
    border-radius: $default-border-radius;
  }
}

.textarea-border {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: rem-calc(2) solid $default-border-focus-color;
}
