button {
  font-family: inherit;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
  }
}

p {
  line-height: inherit
}

strong {
  font-weight: $font-weight-semibold;
}
