.table {
  width: 100%;
  margin-bottom: $default-margin;
  border-collapse: collapse;
  line-height: 1.2;
  table-layout: fixed;

  th,
  td {
    border: rem-calc(1) solid $color-alabaster;
    font-size: 0.875rem;
    vertical-align: top;
    text-align: left;

    &:not(:last-child):not(.table__cell--bordered) {
      border-right-color: transparent;
    }
  }

  th {
    padding: 1rem;
    color: $color-bombay;
    font-weight: $font-weight-regular;
    text-transform: uppercase;
  }

  tr:nth-child(odd):not(.table__row--odd):not(.table__row--even) {
    td:not(.table__cell--clear) {
      background-color: $color-alabaster;
    }
  }

  td {
    padding: rem-calc(14) 1rem;
    color: $color-dove-gray;
  }

  .td--odd {
    background-color: $color-alabaster;
  }

  .table__row--odd {
    td {
      background-color: $color-alabaster;
    }
  }

  .table__row--even {
    td {
      background-color: $color-white;
    }
  }

  .table__cell--clear {
    background-color: $color-white;
  }
}

.table--clear {
  th,
  td {
    border: {
      left: rem-calc(1) solid transparent;
      right: rem-calc(1) solid transparent;
    };
  }

  tr:nth-child(odd):not(.table__row--odd):not(.table__row--even) {
    td {
      background-color: $color-white;
    }
  }
}
