%flex-table__row--first {
  border-top-width: 1px;
}

.flex-table {
  width: 100%;
  line-height: 1.2rem;
  font-size: $flex-table-font-size;

  @include breakgridpoint(medium down) {
    font-size: $flex-table-tablet-font-size;
  }
}

.flex-table__box {
  background-color: $flex-table-bg-color;
  border-color: $flex-table-border-color;

  &.flex-table__box--even {
    background-color: $flex-table-even-bg-color;
  }

  &.flex-table__box--rounded {
    border-width: 1px;
    border-style: solid;
    border-radius: $default-border-radius;

    .flex-table__row {
      border-left-width: 0;
      border-right-width: 0;

      &:first-child {
        &.flex-table__row--header {
          border-top-width: 0;
        }

        &.flex-table__row--content {
          border-top-width: 1px;
        }
      }

      &:last-child {
        &.flex-table__row--header,
        &.flex-table__row--content {
          border-bottom-width: 0;
        }

        &:not(.flex-table__row--header) {
          border-bottom-left-radius: $default-border-radius;
          border-bottom-right-radius: $default-border-radius;
        }
      }
    }
  }

  &.flex-table__box--half-rounded {
    &.flex-table__box--first {
      border-width: 1px;
      border-style: solid;
      border-bottom: 0;
      border-top-left-radius: $default-border-radius;
      border-top-right-radius: $default-border-radius;

      .flex-table__row {
        border-left-width: 0;
        border-right-width: 0;

        &:first-child {
          &.flex-table__row--header {
            border-top-width: 0;
          }
        }
      }
    }

    &.flex-table__box--last {
      border-width: 1px;
      border-style: solid;
      border-top: 0;
      border-bottom-left-radius: $default-border-radius;
      border-bottom-right-radius: $default-border-radius;

      .flex-table__row {
        border-left-width: 0;
        border-right-width: 0;

        &:first-child {
          &.flex-table__row--content {
            border-top-width: 1px;
          }
        }

        &:last-child {
          &.flex-table__row--header,
          &.flex-table__row--content {
            border-bottom-width: 0;
          }

          &:not(.flex-table__row--header) {
            border-bottom-left-radius: $default-border-radius;
            border-bottom-right-radius: $default-border-radius;
          }
        }
      }
    }
  }

  &.flex-table__box--shadow {
    box-shadow: $third-box-shadow;
  }
}

.flex-table__blankslate {
  color: $color-bombay;
  font-size: $flex-table-font-size;
  border: {
    color: $flex-table-border-color;
    top-width: 0;
  }

  &.flex-table__blankslate--full {
    border-top-width: 1px;
  }
}

.flex-table__row {
  width: 100%;
  display: flex;
  background-color: transparent;
  border: {
    color: $flex-table-border-color;
    style: solid;
    width: 1px;
    top-width: 0;
  }

  @include breakgridpoint(medium down) {
    padding: {
      left: $flex-table-tablet-horizontal-padding;
      right: $flex-table-tablet-horizontal-padding;
    }
  }

  &:not(:last-child) {
    border-bottom-width: 0;
  }

  &.flex-table__row--full-border {
    border-width: 1px;
  }

  &.flex-table__row--independent {
    @include breakgridpoint(medium down) {
      border-width: 1px;
      padding: {
        bottom: $flex-table-tablet-vertical-padding;
        top: $flex-table-tablet-vertical-padding;
      }
    }
  }

  &.flex-table__row--indirect:not(.flex-table__row--no-border) {
    &.flex-table__row--first {
      @extend %flex-table__row--first;
    }
  }

  &:not(.flex-table__row--indirect):not(.flex-table__row--no-border) {
    &:first-child {
      @extend %flex-table__row--first;
    }
  }

  &.flex-table__row--even {
    background-color: $flex-table-even-bg-color;
  }

  &.flex-table__row--odd {
    background-color: $flex-table-bg-color;
  }

  &.flex-table__row--transparent {
    background-color: transparent;
  }

  &.flex-table__row--no-border {
    border-width: 0;

    &.flex-table__row--first {
      .flex-table__cell {
        padding-top: $flex-table-padding-vertical;
      }
    }

    .flex-table__cell {
      padding-top: 0;
      padding-bottom: $flex-table-padding-vertical;
    }
  }

  &.flex-table__row--link {
    outline: none;
    cursor: pointer;
  }

  &.flex-table__row--center {
    align-items: center;
  }
}

.flex-table__group {
  display: flex;
  flex-wrap: wrap;

  .flex-table__cell,
  .flex-table__cell--th {
    &:last-child {
      border-right-width: 0;
    }

    &:first-child {
      border-left-width: 0;
    }
  }

  .flex-table__section {
    &:first-child {
      .flex-table__cell,
      .flex-table__cell--th {
        &:first-child {
          border-left-width: 0;
        }
      }
    }

    &:last-child {
      .flex-table__cell,
      .flex-table__cell--th {
        &:last-child {
          border-right-width: 0;
        }
      }
    }
  }

  &:first-child {
    .flex-table__section {
      &:first-child {
        .flex-table__cell,
        .flex-table__cell--th {
          &:first-child {
            border-left-width: rem-calc(1);
          }
        }
      }
    }
  }

  &:last-child {
    .flex-table__section {
      &:last-child {
        .flex-table__cell,
        .flex-table__cell--th {
          &:last-child {
            border-right-width: rem-calc(1);
          }
        }
      }
    }
  }
}

.flex-table__section {
  display: flex;

  &:not(.flex-table__section--nowrap) {
    @include breakgridpoint(medium down) {
      flex-direction: column;
    }
  }

  &.flex-table__section--column {
    flex-direction: column;
  }

  .flex-table__cell,
  .flex-table__cell--th {
    &:last-child {
      border-right-width: 0;
    }

    &:first-child {
      border-left-width: 0;
    }
  }

  &:first-child {
    .flex-table__cell,
    .flex-table__cell--th {
      &:first-child {
        border-left-width: rem-calc(1);
      }
    }
  }

  &:last-child {
    .flex-table__cell,
    .flex-table__cell--th {
      &:last-child {
        border-right-width: rem-calc(1);
      }
    }
  }
}

.flex-table__sub-section {
  display: flex;

  &:not(:first-child) {
    .flex-table__cell {
      padding-top: calc($flex-table-padding-vertical * 1 / 3);
    }
  }

  &:not(:last-child) {
    .flex-table__cell {
      padding-bottom: calc($flex-table-padding-vertical * 1 / 3);
    }
  }
}

.flex-table__cell {
  padding: $flex-table-padding-vertical $flex-table-padding-horizontal;
  word-wrap: break-word;

  @include breakgridpoint(medium down) {
    padding: $flex-table-tablet-vertical-padding $flex-table-tablet-horizontal-padding;
  }

  &.flex-table__cell--center {
    text-align: center;
  }

  &.flex-table__cell--right {
    text-align: right;
  }
}

.flex-table__cell--th {
  @extend .flex-table__cell;
  text-transform: uppercase;
  color: $color-bombay;
}

// Add-ons

.flex-table__cell--avatar {
  @include breakgridpoint(large up) {
    padding: {
      top: $flex-table-padding-vertical - rem-calc(6);
      bottom: $flex-table-padding-vertical - rem-calc(6);
    }
  }
}

.flex-table__cell--abbreviation {
  padding: {
    top: $flex-table-padding-vertical - 0.5rem;
    right: $flex-table-padding-horizontal - rem-calc(2);
    bottom: $flex-table-padding-vertical - 0.5rem;
    left: $flex-table-padding-horizontal - rem-calc(2);
  }

  @include breakgridpoint(medium down) {
    padding: {
      top: $flex-table-tablet-vertical-padding - 0.5rem;
      right: $flex-table-tablet-horizontal-padding - rem-calc(2);
      bottom: $flex-table-tablet-vertical-padding - 0.5rem;
      left: $flex-table-tablet-horizontal-padding - rem-calc(2);
    }
  }

  .flex-table__abbreviation {
    margin: rem-calc(2);
  }
}

.flex-table__options {
  text-align: right;
  padding: {
    top: rem-calc(14);
    bottom: rem-calc(14);
  }

  @include breakgridpoint(medium down) {
    padding: {
      top: rem-calc(6);
      bottom: rem-calc(6);
    }

    &.flex-table__options--big-content {
      padding: {
        top: rem-calc(4);
        bottom: rem-calc(4);
      }
    }
  }

  &.flex-table__options--big-content {
    padding: {
      top: rem-calc(10);
      bottom: rem-calc(10);
    }
  }

  &.flex-table__options--first {
    text-align: left;
  }

  .flex-table__options-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.flex-table__option {
  color: $color-bombay;
  line-height: 1rem;

  &:not(:first-child) {
    margin-left: 1rem;

    @include breakgridpoint(medium down) {
      margin-left: 0.75rem;
    }
  }

  &.flex-table__option--blue {
    color: $color-picton-blue;
  }

  &.flex-table__option--big {
    font-size: rem-calc(20);
    line-height: rem-calc(20);
  }
}

.flex-table__option--slide {
  font-size: rem-calc(14);
  vertical-align: middle;
}

.flex-table__option--more {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.flex-table__option--pen {
  display: inline-block;
  width: rem-calc(24);
  height: rem-calc(24);
  font-size: 1rem;
  border-radius: rem-calc(12);

  &:hover {
    background-color: $color-cerulean;
    color: $color-white;
  }
}

.flex-table__pagination {
  display: block;
  margin-top: 1rem;
}
