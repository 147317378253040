.label {
  display: inline-block;
  text-indent: rem-calc(10);
  color: $default-label-color;
  font-size: rem-calc(13);
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

%label__icon {
  line-height: 0.5rem;
  vertical-align: -0.25rem;
  font-size: 1.25rem;
}

.label--light {
  color: $color-nepal;
  text-indent: unset;
}

.label--blue {
  color: $color-rock-blue;
}

.label--biscay {
  color: $color-biscay;
}

.label--dark-blue {
  color: $color-chambray;
}

.label--gray {
  color: $color-scorpion;
}

.label--bermuda-gray {
  color: $color-bermuda-gray;
}

.label--dove-gray {
  color: $color-dove-gray;
}

.label--inactive {
  color: $color-dusty-gray;
}

.label--green {
  color: $color-fruit-salad;
}

.label--regular {
  font-weight: $font-weight-regular;
}

.label--semibold {
  font-weight: $font-weight-semibold;
}

.label--bold {
  font-weight: $font-weight-bold;
}

.label--small {
  font-size: rem-calc(15);
}

.label--medium {
  font-size: 1rem;
}

.label--disabled {
  color: $color-bombay;
}

.label--not-nested {
  text-indent: 0;
  margin-bottom: 0;
}

.label--without-margin {
  margin-bottom: 0;
}

.label--uppercase {
  text-transform: uppercase;
}

.label-icon {
  @extend %label__icon;
}

.label--no-wrap {
  white-space: nowrap;
}

.label-icon--before {
  @extend %label__icon;
  margin-right: 0.5rem;
}

.label-icon--after {
  @extend %label__icon;
  margin-left: 0.5rem;
}

.label--modal {
  font-size: rem-calc(15);
}

.label--required {
  &:after {
    content: '*';
    display: inline;
  }
}
