@import '~foundation-sites/scss/util/unit';
@import '~foundation-sites/scss/util/value';
@import 'gutwin-shared/scss/base/variables';

$color-aqua-forest: #599779;
$color-de-york: #70BD80;
$color-dodger-blue: #33D0FF;
$color-fern: #58BD5E;
$color-golden-tainoi: #FFD065;
$color-heliotrope: #B96CF5;
$color-malibu: #5B9CFF;
$color-neon-carot: #FD9933;
$color-ottoman: #EFFAEF;
$color-red: #FF0000;
$color-sweet-pink: #FFA0A0;

$breakgridpoint-usage: 'media';
