@import './../utils/zindexes';
@import '~foundation-sites/scss/util/unit';

.dropdown {
  position: relative;

  &:focus {
    outline: none;
  }

  &.dropdown--opened {
    z-index: index($page, dropdown);

    > .dropdown__content {
      animation: {
        name: openDropdown;
        timing-function: ease-in;
        duration: 0.2s;
        fill-mode: forwards;
      }
    }

    > .dropdown__icon {
      transform: rotate(-180deg);
    }
  }

  &.dropdown--closed {
    > .dropdown__content {
      animation: {
        name: closeDropdown;
        timing-function: ease-in;
        duration: 0.2s;
        fill-mode: forwards;
      }
    }
  }

  &.dropdown--max-width {
    width: 100%;
  }

  &.dropdown--tooltip {
    display: inline-block;
  }
}

.dropdown__content {
  display: block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $color-white;
  box-shadow: $default-box-shadow;
  border-radius: $default-border-radius;
  overflow: hidden;
  text-align: left;

  &.dropdown__content--visible {
    margin-top: rem-calc(4);
    min-width: 15rem;
    visibility: visible;
    opacity: 1;
    z-index: index($page, dropdown);
  }

  &.dropdown__content--hidden {
    display: none;
  }

  &.dropdown__content--under {
    top: 100%;
  }

  &.dropdown__content--right {
    right: 0;
    left: auto;
  }
}

.dropdown__icon {
  display: inline-block;
  transition: $default-transform-transition;
}

.dropdown__actions {
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  border-top: rem-calc(1) solid $color-alabaster;
  text-align: right;
}

.dropdown--date-time-picker {
  > .dropdown__content {
    width: 18rem;
    top: -2rem;
    left: -1rem;

    @include breakpoint(large up) {
      width: 30rem;
    }

    &.dropdown__content--horizontal-overflow {
      left: auto;
      right: -1rem;
    }
  }
}

.dropdown--date-picker {
  > .dropdown__content {
    width: 18rem;
    top: 100%;
    left: 0;

    &.dropdown__content--vertical-overflow {
      top: auto;
      bottom: 100%;
    }
  }
}

.dropdown--single-date-picker {
  > .dropdown__content {
    width: 18rem;
    top: 0;
    left: calc(100% + 0.5rem);
    padding: 1rem;

    &.dropdown__content--horizontal-overflow {
      top: 100%;
      left: 0;
    }
  }
}

.dropdown--menu {
  .dropdown__content:not(.dropdown__content--right) {
    left: auto;
    right: 0;
  }

  .dropdown-menu__list {
    list-style-type: none;
    margin: 0;
    padding: 0.5rem 0;

    &:not(:first-child) {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0.5rem;
        left: 0.5rem;
        border-top: rem-calc(1) solid $color-casper;
      }
    }

    &.dropdown-menu__list--info {
      padding: 0.25rem 0;

      .dropdown-menu__item {
        width: max-content;
        padding: 0.25rem 0.5rem;
        color: $default-text-color;
        font: {
          size: rem-calc(14);
          weight: $font-weight-regular;
        }
        text-transform: none;
        cursor: default;
      }
    }

    &.dropdown-menu__list--small {
      padding: 0;
    }
  }

  .dropdown-menu__link {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    position: relative;
    white-space: nowrap;
    color: $default-text-color;
    line-height: inherit;
    text: {
      align: left;
      transform: none;
    }
    font: {
      weight: $font-weight-regular;
      size: rem-calc(14);
    }
  }
}

.dropdown--client {
  color: $color-scorpion;

  .dropdown__content {
    left: auto;
    right: -1rem;
    top: 2rem;
  }

  .dropdown__content--max-width {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
    left: auto;
    right: 0;
    top: 3.5rem;
    width: 100%;
  }

  .dropdown-menu__header {
    color: $color-chambray;
    font-weight: $font-weight-semibold;
    text-transform: none;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      text-decoration: underline;
    }

    .dropdown-menu__header--completed {
      color: $color-scorpion;
    }
  }

  .dropdown-menu__list {
    list-style-type: none;
    margin: 0;
    text-align: center;
    white-space: nowrap;
  }

  .dropdown-menu__link {
    color: $color-scorpion;
    font-weight: $font-weight-semibold;
    height: 100%;
    padding: rem-calc(14) rem-calc(35);
    text: {
      align: center;
      transform: none;
    }
    width: 100%;

    &.dropdown-menu__link--chosen {
      color: $color-fruit-salad;
    }

    &.dropdown-menu__link--bigger-padding {
      padding: rem-calc(20) 1rem;
    }

    &.dropdown-menu__link--kebab-menu {
      font-weight: $font-weight-regular;
      padding: 1rem rem-calc(20);
      text-align: right;
    }
  }

  .dropdown-menu__item {
    color: $default-text-color;
    font: {
      size: rem-calc(16);
      weight: $font-weight-regular;
    }
    text-transform: none;
    cursor: default;

    &:hover {
      background: $color-polar;
    }
  }
}
