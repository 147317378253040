$triangle-size: 5px !default;
$triangle-position-top: calc(50% - 2px) !default;
$triangle-position-right: 20px !default;
$triangle-drop-shadow: 0 2px 2px rgb(0 0 0 / 10%) !default;

$angle-size: 6px !default;
$angle-thickness: 2px !default;
$angle-position-top: calc(50% - 2px) !default;
$angle-position-down: calc(50% - 4px) !default;
$angle-position-right: 22px !default;

@mixin triangle($color: $black, $size: $triangle-size, $top: $triangle-position-top, $right: $triangle-position-right, $rotate: 0, $drop-shadow: false) {
  &::after {
    position: absolute;
    content: '';
    top: $top;
    right: $right;
    width: 0;
    height: 0;
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
    border-bottom: none;
    transform: rotate(#{$rotate}deg);

    @if $drop-shadow {
      filter: drop-shadow($triangle-drop-shadow);
    }
  }
}

@mixin triangle-down($color: $black, $size: $triangle-size, $top: $triangle-position-top, $right: $triangle-position-right, $drop-shadow: false) {
  @include triangle($color, $size, $top, $right, 0, $drop-shadow);
}

@mixin triangle-up($color: $black, $size: $triangle-size, $top: $triangle-position-top, $right: $triangle-position-right, $drop-shadow: false) {
  @include triangle($color, $size, $top, $right, 180, $drop-shadow);
}

@mixin angle($color: $black, $size: $angle-size, $thickness: $angle-thickness, $top: $angle-position-top, $right: $angle-position-right, $rotate: 0) {
  &::after {
    position: absolute;
    content: '';
    top: $top;
    right: $right;
    width: $size;
    height: $size;
    border-top: $thickness solid $color;
    border-right: $thickness solid $color;
    border-bottom: none;
    border-left: none;
    transform: rotate(#{135 + $rotate}deg);
    transition: 0.3s ease-in all;
  }
}

@mixin angle-down($color: $black, $size: $angle-size, $thickness: $angle-thickness, $top: $angle-position-down, $right: $angle-position-right) {
  @include angle($color, $size, $thickness, $top, $right, 0);
}

@mixin angle-up($color: $black, $size: $angle-size, $thickness: $angle-thickness, $top: $angle-position-top, $right: $angle-position-right) {
  @include angle($color, $size, $thickness, $top, $right, 180);
}

@mixin set-icon-color($color, $type: 'triangle',) {
  &::after {
    border-top-color: $color;
    @if $type == 'angle' {
      border-right-color: $color;
    }
  }
}

@mixin set-icon-direction($direction, $type: 'triangle') {
  &::after {
    @if $type == 'triangle' {
      top: $triangle-position-top;
    } @else if $type == 'angle' {
      @if $direction == 'up' {
        top: $angle-position-top;
      } @else if $direction == 'down' {
        top: $angle-position-down;
      }
    }
  }
}

@mixin set-icon-rotation($rotate, $type: 'triangle') {
  &::after {
    @if $type == 'triangle' {
      transform: rotate(#{$rotate}deg);
    } @else if $type == 'angle' {
      transform: rotate(#{135 + $rotate}deg);
    }
  }
}

@mixin set-icon-position($top, $right) {
  &::after {
    top: $top;
    right: $right;
  }
}
