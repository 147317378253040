.spinner {
  position: absolute;
  top: rem-calc(-13);
  left: 0;
  display: inline-block;
  width: $spinner-size;
  height: $spinner-size;
  border: $spinner-border-size solid rgba($color-white, 0.2);
  border-left-color: $color-white;
  border-radius: 50%;
  animation: rollSpinner 1s infinite linear;

  &.spinner--big {
    width: $spinner-size-big;
    height: $spinner-size-big;
  }

  &.spinner--blue {
    border-color:rgba($color-chambray, 0.2);
    border-left-color: $color-chambray;
  }
}

@keyframes rollSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
