@import '~foundation-sites/scss/util/unit';
@import './../base/variables';

$side-panel-font-color: rgba($color-white, 0.8);
$side-panel-active-font-color: $color-white;
$side-panel-light-font-color: $color-biscay;
$side-panel-link-margin: 0.5rem;
$side-panel-content-height: rem-calc(300);

%side-panel__header--focus {
  color: $side-panel-active-font-color;
  background-color: $color-kashmir-blue;
}

%side-panel-light__header--focus {
  $color-bg-hover: lighten($color-botticelli, 5%);

  color: $color-biscay;
  background-color: $color-bg-hover;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-bg-hover;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-bg-hover;
  }
}

%side-panel__link--focus {
  color: $side-panel-active-font-color;
  font-weight: $font-weight-semibold;
}

.side-panel__container {
  color: $side-panel-font-color;
  background-color: $color-chambray;
  border-right: rem-calc(1) solid $color-waikawa-gray;

  &.side-panel__container--light {
    color: $side-panel-light-font-color;
    background-color: $color-hawkes-blue;
    border-right: rem-calc(1) solid rgba($color-biscay, 0.2);

    .side-panel__box {
      display: block;

      &:not(:last-child) {
        border-bottom: rem-calc(1) solid $color-heather;
      }
    }

    .side-panel__header {
      font-weight: $font-weight-regular;
      transition: all 0.2s;

      &:hover,
      &:focus,
      &.side-panel__header--active {
        @extend %side-panel-light__header--focus;
      }
    }

    .side-panel__header--bold {
      font-weight: $font-weight-semibold;
    }
  }

  &.side-panel__container--client {
    color: $color-chambray;
    background-color: $color-aqua-haze;
    border-right: 1px solid $color-aqua-haze;
  }

  &.side-panel__container--inner-shadow {
    box-shadow: inset rem-calc(6) 0 rem-calc(6) rgba($color-black, 0.1);
  }
}

.side-panel__box:last-child .side-panel__link:last-child {
  margin-bottom: 1rem;
}

.side-panel__content {
  max-height: $side-panel-content-height;
  overflow: auto;
  padding: {
    right: 1rem;
    left: 1rem;
  }

  &.side-panel__content--virtual-scroll {
    height: $side-panel-content-height;
  }
}

.side-panel__content--fit {
  max-height: unset;
  overflow: unset;
}

.side-panel__header {
  padding: 0.75rem 0;
  font-weight: $font-weight-semibold;
  cursor: pointer;

  &:hover,
  &:focus {
    @extend %side-panel__header--focus;
  }

  &.side-panel__header--active {
    @extend %side-panel__header--focus;
    border-left: 0.3rem solid $color-picton-blue;

    .side-panel__header-columns {
      margin-left: -0.3rem;
    }
  }

  &.side-panel__header--no-padding {
    padding: 0;
  }
}

.side-panel__header-title {
  width: 100%;

  &.side-panel__header-title--with-padding {
    padding: calc($side-panel-link-margin / 2) 0 {
      left: 1rem;
    }
  }

  &.side-panel__header-title--gray {
    color: $side-panel-font-color;
  }

  &.side-panel__header-title--font-regular {
    font-weight: $font-weight-regular;
  }
}

.side-panel__header-content {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.side-panel__header-icon {
  font-size: rem-calc(12);
  color: $color-white;
  text-align: right;
}

.side-panel__header-icon--dark {
  color: $color-biscay;
}

.side-panel__link {
  position: relative;
  display: block;
  color: $side-panel-font-color;
  text-decoration: none;
  width: auto;
  margin: calc($side-panel-link-margin / 2) 0 {
    left: 1rem;
  }

  &:hover,
  &:focus {
    @extend %side-panel__link--focus;
    outline: none;
  }

  &.side-panel__link--active {
    @extend %side-panel__link--focus;

    &:before {
      content: '';
      position: absolute;
      left: rem-calc(-13);
      top: calc(50% - #{rem-calc(1)});
      display: inline-block;
      width: rem-calc(5);
      height: rem-calc(5);
      background-color: $color-picton-blue;
      border-radius: rem-calc(1);
    }
  }

  &.side-panel__link--disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.side-panel__content-wrapper {
  display: table;
}

.side-panel__links {
  overflow: hidden;
  width: 100%;
}
