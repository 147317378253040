.grid {
  display: flex;

  .grid {
    margin: {
      right: -1rem;
      left: -1rem;
    };
  }
  &.grid--column {
    flex-direction: column;
  }

  &.grid--wrap {
    flex-wrap: wrap;
  }

  @for $size_index from 1 to (length($grid-sizes) + 1) {
    &.grid--#{nth($grid-sizes, $size_index)} {
      @for $i from 1 to 13 {
        .grid__column--#{nth($grid-sizes, $size_index)}-#{$i} {
          max-width: 100% * ($i / 12);
          flex-basis: 100% * ($i / 12);
          padding: {
            right: 1rem;
            left: 1rem;
          };
        }
      }
    }
  }
}
