@import '../base/variables';
@import '../utils/zindexes';
@import 'modal-header';
@import '~foundation-sites/scss/util/breakpoint';
@import '~foundation-sites/scss/foundation';
@include foundation-flex-grid;

$default-modal-width: 50rem;
$default-modal-padding: 2rem;
$second-modal-padding: 2.5rem;
$small-modal-width: rem-calc(540);
$modal-padding: 3rem;
$modal-padding-small: 0;
$modal-header-height: rem-calc(28);
$modal-header-margin: 1rem;
$modal-header-full-height: $modal-header-height + $modal-header-margin;
$modal-actions-height: rem-calc(44);
$modal-actions-padding: 1rem;
$modal-actions-full-height: $modal-actions-height + $modal-actions-padding;
$modal-elements-height: $modal-header-full-height + $modal-actions-full-height;
$modal-offcanvas-bp-xlarge: 1225px;

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: index($page, modal);
  display: block;
  padding: $modal-padding-small;
  overflow: auto;

  &.modal--centered {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }

  @include breakpoint(medium up) {
    padding: $modal-padding;
  }
}

.modal--full-height {
  &.modal--content-only {
    .modal__content {
      height: 100%;
    }
  }

  &.modal--without-header {
    .modal__content {
      height: calc(100% - #{$modal-header-full-height});
    }
  }

  &.modal--without-actions {
    .modal__content {
      height: calc(100% - #{$modal-actions-full-height});
    }
  }

  .modal__row {
    height: 100%;
  }

  .modal__columns {
    height: 100%;
  }

  .modal__body {
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: stretch;
  }

  .modal__content {
    height: calc(100% - #{$modal-elements-height});
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
  }
}

.modal--full-width {
  .modal__body {
    max-width: 100%;
  }
}

.modal--hidden {
  display: none;
}

.modal--info {
  padding-top: 12rem;

  .modal__body {
    padding: 0;
  }

  .modal__heading {
    height: 3rem;
    padding-left: 2rem;
    margin: 0;
    background-color: $color-biscay;
    display: flex;
    align-items: center;
    border-top-left-radius: $double-border-radius;
    border-top-right-radius: $double-border-radius;
  }

  .modal__close {
    color: $color-white
  }

  .modal__content  {
    padding: 2rem;
  }
}

.modal__blanket {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color-black, 0.25);
  z-index: index($page, modal-blanket);
}

.modal__columns {
  @include breakpoint(medium down) {
    padding: 0;
  }
}

.modal__close {
  position: absolute;
  display: block;
  top: 0.875rem;
  right: 0.875rem;
  color: $color-gray;
  font-size: 1.125rem;

  &:hover {
    color: $color-dove-gray;
  }
}

.modal__body {
  padding: $default-modal-padding;
  background: $color-white;
  border-radius: $double-border-radius;
  margin: 0 auto;
  position: relative;
  max-width: $default-modal-width;

  &.modal__body-bigger-padding {
    padding: $second-modal-padding;
  }

  @include breakpoint(small down) {
    min-height: 100vh;
  }
}

.modal__body--w {
  @extend .modal__body;
  max-width: $small-modal-width;
}

.modal__body--small {
  @extend .modal__body;
  max-width: $small-modal-width;
}

.modal__actions {
  margin-top: 1rem;
  text-align: right;
}

.modal--off-canvas {
  @extend .modal--full-height;
  padding: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: auto;
    width: calc(50% - 15px);
    background: $color-white;
  }

  &.modal--alabaster {
    &:before {
      background-color: $color-alabaster;
    }

    .modal__body {
      background-color: $color-alabaster;
    }

    .modal__columns {
      @include breakpoint(small down) {
        background-color: $color-alabaster;
      }
    }
  }

  &.modal--no-padding-content {
    .modal__body {
      padding: {
        right: 0;
        left: 0;
      }
    }

    .modal__actions,
    .modal__content {
      padding: {
        right: $default-modal-padding;
        left: $default-modal-padding;
      }
    }

    .modal__content {
      @include breakpoint(medium up) {
        padding: {
          right: 0;
          left: 0;
        }
      }
    }
  }

  .modal__row {
    margin: 0 auto;
    height: 100%;
  }

  .modal__columns {
    @include flex-grid-column(12);
    background-color: $color-white;

    @include breakpoint(medium up) {
      @include flex-grid-column(6);
      margin-left: 50%;
      background-color: transparent;
    }
  }

  .modal__body {
    max-width: none;
    height: 100%;
    border-radius: 0;
    padding: {
      top: 3rem;
      right: 1rem;
      left: 1rem;
    }

    @include breakpoint(medium up) {
      box-shadow: -0.6rem 0 1rem 0 rgba($color-black, 0.16);
    }

    @include breakpoint($modal-offcanvas-bp-xlarge up) {
      padding-top: $default-modal-padding;
    }
  }

  .modal__close {
    @include breakpoint($modal-offcanvas-bp-xlarge up) {
      right: -0.5rem;
    }
  }
}
