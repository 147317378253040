$expand-box-shadow: 0 rem-calc(2) rem-calc(6) rgba($color-black, 0.1);

.expand-box {
  max-width: 100%;
  width: 100%;
}

.expand-mask {
  &:after {
    content: '';
    display: block;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(black, 0.25);
    z-index: index($expand-mask, background);
    transition: opacity 0.2s ease-in;
  }
}

.expand-mask__toggle-icon,
.expand-box__toggle-icon {
  display: inline-block;
  transition: $default-transform-transition;
}

.expand-mask--opened,
.expand-box--opened {
  display: block;

  &.expand-box--shadow {
    box-shadow: $expand-box-shadow;
  }

  &:after {
    opacity: 1;
    visibility: visible;
  }

  .expand-mask__content {
    position: relative;
    background-color: $color-white;
    z-index: index($expand-mask, content);
  }

  .expand-mask__toggle-icon,
  .expand-box__toggle-icon {
    transform: rotate(-180deg);

    &.expand-mask__toggle-icon--half,
    &.expand-box__toggle-icon--half {
      transform: rotate(-90deg);
    }

    &.expand-mask__toggle-icon--half-back,
    &.expand-box__toggle-icon--half-back {
      transform: rotate(90deg);
    }
  }
}

.expand-mask--closed {
  &:after {
    opacity: 0;
    visibility: hidden;
  }
}

.expand-box--shadow-always {
  box-shadow: $expand-box-shadow;
}
