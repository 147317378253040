$global-font-size: 16px;
$body-font-family: 'Source Sans Pro';
$global-weight-bold: $font-weight-bold;
$global-margin: $default-margin;
$header-font-weight: $global-weight-bold;
$grid-row-width: none;
$grid-column-gutter: (
  small: 1.5rem,
  medium: 2rem,
);

// Forms
$form-spacing: 1.5rem;
$input-font-family: inherit;
$input-font-size: 1rem;
$input-font-weight: 400;

