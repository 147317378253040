$select-padding: rem-calc(10) rem-calc(9);
$select-padding-right: 2.25rem;
$select-angle-icon-size: 0.5rem;

%select {
  height: rem-calc(45);
  margin: 0;
  appearance: none;
  line-height: 1.5;
  color: $select-color;
  box-shadow: 0 0 rem-calc(2) 0 rgba($color-black, 0.06), 0 rem-calc(2) rem-calc(2) 0 rgba($color-black, 0.06);
  padding: $select-padding {
    right: $select-padding-right;
  };
  font: {
    family: $input-font-family;
    size: $input-font-size;
  };
  border: rem-calc(1) solid;
  border-color: $color-alabaster;
  border-top-color: $color-white;
  border-radius: $select-radius;
  background-color: $select-background;
  cursor: pointer;
}

%select--focus {
  outline: none;
  background-color: $color-seashell;
  border-color: $color-seashell;
  box-shadow: 0 0 rem-calc(2) 0 rgba($color-black, 0.12), 0 rem-calc(2) rem-calc(2) 0 rgba($color-black, 0.12);
}

.select {
  @extend %select;
  position: relative;
  background: {
    origin: content-box;
    position: right (-$form-spacing) top rem-calc(10);
    repeat: no-repeat;
    size: rem-calc(9) rem-calc(6);
  };
  @include triangle-down($select-triangle-color);

  &:focus {
    @extend %select--focus;
  }

  &:disabled,
  &.select--disabled {
    color: lighten($select-color, 25%);
    cursor: default;
    @include set-icon-color($select-disabled-triangle-color);

    &.select--angle-icon {
      @include set-icon-color($select-disabled-triangle-color, 'angle');
    }
  }

  &::-ms-expand {
    display: none;
  }

  &.select--angle-icon {
    @include angle-down($select-angle-color, $select-angle-icon-size);
  }

  &.select--opened {
    @include set-icon-direction('up');
    @include set-icon-rotation(-180);

    &.select--angle-icon {
      @include set-icon-direction('up', 'angle');
      @include set-icon-rotation(-180, 'angle');
    }
  }

  &.select--placeholder {
    color: $default-input-placeholder-color;
  }

  &.select--white {
    background-color: $color-white;
  }

  &.select--colored {
    color: $color-white;
    border-width: 0;
    @include set-icon-color($color-white);

    &.select--angle-icon {
      @include set-icon-color($color-white, 'angle');
    }
  }

  &.select--transparent,
  &.select--dark-transparent,
  &.select--black-transparent,
  &.select--blue-transparent {
    background-color: transparent;
    border: 0;
    box-shadow: none;

    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &.select--blue-transparent {
    font-weight: $font-weight-semibold;
    color: $color-chambray;
    @include set-icon-color($color-picton-blue);

    &.select--angle-icon {
      @include set-icon-color($color-picton-blue, 'angle');
    }

    &:focus {
      color: $color-chambray;
    }
  }

  &.select--black-transparent {
    &:focus {
      color: $color-black;
    }
  }

  &.select--transparent, &.select--dark-transparent {
    color: $color-casper;

    &:focus {
      color: $color-white;
    }
  }

  &.select--dark-transparent {
    &:focus {
      color: $color-casper;
    }
  }

  &.select--classic {
    padding: $input-classic-vertical-padding $input-classic-horizontal-padding {
      right: $select-padding-right;
    };
    background: {
      color: $color-white;
      position: right (-$form-spacing) top rem-calc(8);
    };
    border: rem-calc(1) solid $default-border-color;
    border-radius: 0;
    line-height: 1.15;
    box-shadow: none;
  }

  &.select--classic-rounded {
    @extend .select--classic;
    border-color: $color-alto;
    border-radius: $triple-border-radius;

    &.select--angle-icon {
      @include set-icon-color($color-bermuda-gray, 'angle');
    }
  }

  &.select--primary {
    color: $color-chambray;
  }

  &.select--clean {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  &.select--error {
    border-bottom: {
      width: rem-calc(2);
      color: $default-border-error-color;
    };
  }

  &.select--no-arrow {
    padding-right: rem-calc(9);

    &::after {
      content: none;
    }
  }
}
