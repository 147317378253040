%finding__status {
  font-weight: $font-weight-semibold;
}

.finding__status--in_progress {
  @extend %finding__status;
  color: $color-azure;
}

.finding__status--not_assigned {
  @extend %finding__status;
  color: $color-vivid-tangerine;
}

.finding__status--solved {
  @extend %finding__status;
  color: $color-fern;
}

.finding-icon {
  display: inline-block;
  font-size: rem-calc(18);
  vertical-align: -0.2rem;
  margin-right: 0.5rem;
  color: $color-bombay;
}

.finding__item {
  margin-bottom: 0.5rem;
  line-height: inherit;

  &:last-child {
    margin-bottom: 0;
  }
}

.finding__item--cause,
.finding__item--solution,
.finding__item--type,
.finding__item--problem {
  @extend .finding__item;
}

.finding__item--light {
  color: $color-gray;
}

.finding__item--problem {
  font-weight: $font-weight-semibold;
}
